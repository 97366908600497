h1 {
    margin: 0;
}

.post-header {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 100px 20px 24px 20px;
    background-color: peru;
    font-family: 'Open Sans', sans-serif;
    color: white;
}

.post-header-text {
    width: 680px;
}

.post-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 200px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
}

.post-text {
    margin: .8em 16px 0 16px;
    max-width: 680px;
    color: #333;
    line-height: 1.8em;
    font-family: 'Open Sans', sans-serif;
}

.post-video {
    margin: 2em;
}

.home-link-container {
    width: 680px;
    font-size: 0.8em;
}